
import { plainToClass } from 'class-transformer';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import StopperCard from '@/components/StopperCard.vue';
import Table from '@/shared/components/Table.vue';
import { Stopper } from '@/shared/models/Stopper';
import { Wine } from '@/shared/models/Wine';

export default {
    components: {
        StopperCard,
        Table,
    },
    setup() {
        const items = ref(Array<Stopper>());
        const store = useStore();

        function getStoppers() {
            store.dispatch('STOPPERS_GET', { currentPage: 1 }).then((res) => {
                items.value = plainToClass(Stopper, res.data.data as Stopper[]);
            });
        }

        getStoppers();

        const router = useRouter();

        function openStopper(wine: Wine) {
            const id = String(wine.id);
            router.push({ name: 'StopperDetail', params: { id } });
        }

        return {
            openStopper,
            items,
        };
    },
};
