
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { debounce } from '@/helpers/Debounce';
import CounterButton from '@/shared/components/CounterButton.vue';
import Tooltip from '@/shared/components/Tooltip.vue';
import { Stopper } from '@/shared/models/Stopper';

export default {
    components: { Tooltip, CounterButton },
    props: {
        stopper: {
            type: Stopper,
            required: true,
        },
    },
    setup(props: any) {
        const store = useStore();
        const stopperRef = ref(props.stopper);
        const quantity = computed(() => Math.floor(stopperRef.value.quantity / stopperRef.value.units_per_parcel));
        const debouncer = debounce(() => {
            store.dispatch('CART_UPDATE', {
                item_no: String(stopperRef.value.no),
                quantity: stopperRef.value.quantity,
            });
        }, 1000);

        function addToCart(amount: any) {
            const dataLayer = window.dataLayer || [];
            let eventType = 'add_to_cart';
            if (amount < 0) {
                eventType = 'remove_from_cart';
            }

            dataLayer.push({
                ecommerce: null,
            });

            dataLayer.push({
                event: eventType,
                ecommerce: {
                    currencyCode: 'EUR',
                    value: stopperRef.value.price,
                    items: [
                        {
                            item_id: stopperRef.value.no,
                            item_name: stopperRef.value.name,
                            index: 0,
                            item_brand: stopperRef.value.color,
                            item_category: stopperRef.value.country.name,
                            item_category2: stopperRef.value.region.name,
                            item_category3: stopperRef.value.estate.name,
                            item_category4: `${stopperRef.value.alcohol_percentage}%`,
                            item_category5: stopperRef.value.grapes.map((grape) => grape.name).join(', '),
                            price: stopperRef.value.price,
                            quantity: amount * stopperRef.value.units_per_parcel,
                        },
                    ],
                },
            });
            stopperRef.value.quantity += amount * stopperRef.value.units_per_parcel;
            debouncer();
        }

        function truncate(string: string) {
            const max = 47;
            return string.length > max ? `${string.slice(0, max - 1)}…` : string;
        }

        return {
            stopperRef,
            truncate,
            addToCart,
            quantity,
            showTooltip: ref(false),
        };
    },
};
